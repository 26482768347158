import React from 'react';

const ConferenceSection = () => {
  return (
    <section className="overflow-hidden relative bg-white dark:bg-gray-900">
      <div className="gap-8 py-8 px-4 mx-auto max-w-screen-xl lg:py-16 xl:grid xl:grid-cols-12">
        <div className="col-span-8">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            About <span className='text-lime-400'>DGOF</span> - Who we are
          </h1>
          <p className="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            We believe that ours is a world of possibilities. But not everyone has access to this truth or is able to tap into the limitless possibilities to weave a meaningful life for themselves and those around them.
          </p>
          <div className="gap-16 items-center sm:flex">
            <div className="mb-8 text-gray-500 sm:mb-0 dark:text-gray-400">
              <svg className="mb-3 w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
              </svg>
              <h2 className="mb-3 text-xl font-semibold text-gray-900 dark:text-white">What drives us?</h2>
              <p className="mb-4 font-light">
                With a mission to reach out to Enkindle and Empower, we aim to touch the lives of underprivileged soul, empowering them with the skills required to transform and excel.
              </p>
              {/* <a
                href="#"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Conference
                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </a> */}
            </div>
            <div className="text-gray-500 dark:text-gray-400">
              <svg className="mb-3 w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clipRule="evenodd"></path>
              </svg>
              <h2 className="mb-3 text-xl font-semibold text-gray-900 dark:text-white">Causes that Matter</h2>
              <p className="mb-4 font-light">
                From education to women empowerment and elder care programmes to planet preservation and sustainability, DGOF seeks to cultivate better future-ready citizens.
              </p>
            </div>
          </div>
        </div>
        <div className="hidden absolute top-0 right-0 w-1/3 h-full xl:block">
          <img
            className="object-cover w-full h-full"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/conference-speaker.jpg"
            alt="Conference speaker"
          />
        </div>
      </div>
    </section>
  );
};

export default ConferenceSection;
