import React from 'react';

const FeatureSection = () => {
  return (
    <section className="bg-white">
      <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 xl:gap-16 sm:py-16 lg:px-6">
        <img
          className="mb-4 w-full lg:mb-0 rounded-lg"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-long.png"
          alt="feature image"
        />
        <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
            <span className='font-thin'>THE <span className='text-lime-400 font-normal'> DGOF </span> Vision</span>
          </h2>
          <p className="mb-8 font-light lg:text-xl">
            As a society, we have somehow forgotten the power of kindness. But if we take the time to observe the world around us, we will realise that it needs a lot more than we can give. That is where the thought of DGOF found its igniting sparks.
          </p>
          <div className="py-8 mb-6 border-t border-b border-gray-200 dark:border-gray-700">
            {features.map((feature, index) => (
              <div className="flex pt-8" key={index}>
                <div className={`flex justify-center items-center mr-4 w-8 h-8 rounded-full ${feature.bgColor} shrink-0`}>
                  <svg
                    className={`w-5 h-5 ${feature.iconColor}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fillRule="evenodd" d={feature.iconPath} clipRule="evenodd"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="mb-2 text-xl font-bold text-gray-900">{feature.title}</h3>
                  <p className="mb-2 font-light text-gray-500">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <p className="text-sm">
            DGOF aims to build an equitable relationship of strength, sustenance and dignity to remind the world of what it means to be human first.
          </p>
        </div>
      </div>
    </section>
  );
};

// Feature data to be mapped
const features = [
  {
    title: '',
    description: 'To lay the groundwork for a better life in children through education and skill training',
    iconPath: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
    bgColor: 'bg-primary-100 dark:bg-primary-900',
    iconColor: 'text-primary-600 dark:text-primary-300',
    linkColor: 'text-primary-600',
    hoverColor: 'text-primary-800',
    darkLinkColor: 'dark:text-primary-500',
    darkHoverColor: 'dark:hover:text-primary-600',
  },
  {
    title: '',
    description: 'To empower women and elderly people to live with independence and dignity',
    iconPath: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
    bgColor: 'bg-primary-100 dark:bg-primary-900',
    iconColor: 'text-primary-600 dark:text-primary-300',
    linkColor: 'text-primary-600',
    hoverColor: 'text-primary-800',
    darkLinkColor: 'dark:text-primary-500',
    darkHoverColor: 'dark:hover:text-primary-600',
  },
  {
    title: '',
    description: 'To install the importance of preserving our home and planet through effective undertakings',
    iconPath: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
    bgColor: 'bg-primary-100 dark:bg-primary-900',
    iconColor: 'text-primary-600 dark:text-primary-300',
    linkColor: 'text-primary-600',
    hoverColor: 'text-primary-800',
    darkLinkColor: 'dark:text-primary-500',
    darkHoverColor: 'dark:hover:text-primary-600',
  },
  {
    title: '',
    description: 'To help underprivileged youth bring their lives to the frontline through education, skill training and job placements',
    iconPath: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
    bgColor: 'bg-primary-100 dark:bg-primary-900',
    iconColor: 'text-primary-600 dark:text-primary-300',
    linkColor: 'text-primary-600',
    hoverColor: 'text-primary-800',
    darkLinkColor: 'dark:text-primary-500',
    darkHoverColor: 'dark:hover:text-primary-600',
  },
  {
    title: '',
    description: 'To aid aspiring youth in becoming potential professionals',
    iconPath: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
    bgColor: 'bg-primary-100 dark:bg-primary-900',
    iconColor: 'text-primary-600 dark:text-primary-300',
    linkColor: 'text-primary-600',
    hoverColor: 'text-primary-800',
    darkLinkColor: 'dark:text-primary-500',
    darkHoverColor: 'dark:hover:text-primary-600',
  },
];

export default FeatureSection;
