import React from 'react';
import dp from '../img/jm.png'

const TeamMemberSection = () => {
    return (
        <section className="bg-gradient-to-r from-blue-50 to-blue-200 dark:from-gray-800 dark:to-gray-900 w-full py-16">
            <div className="max-w-6xl mx-auto relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 dark:from-gray-700 dark:via-gray-800 dark:to-gray-900 opacity-50 -z-10"></div>

                <div className="flex flex-col md:flex-row items-center justify-between bg-white dark:bg-gray-900 p-8 md:p-12 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 relative">
                    <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-8 relative">
                        <img
                            className="w-56 h-56 rounded-full border-8 border-blue-300 dark:border-gray-700 shadow-lg transform transition-transform duration-300 hover:scale-105"
                            src={dp}
                            alt="Judit Milton"
                        />
                        <div className="absolute inset-0 rounded-full bg-blue-100 dark:bg-gray-800 -z-10"></div>
                    </div>
                    <div className="flex-1">
                        <h2 className="text-4xl font-semibold text-gray-900 dark:text-white mb-2">
                            Judit Milton
                        </h2>
                        <h3 className="text-xl font-medium text-gray-700 dark:text-gray-400 mb-6">
                            President & Managing Trustee
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                            Judit Milton is a Montessorian with 15+ years of experience in early childhood education. She’s held the office of Head Administrator with a leading corporate preschool in India for over a decade. She has a Master's degree in Child Care and Education and BSS’ Montessori and Child Education programme certifications.
                        </p>
                        <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                            Her interest in social work is what led her to start DGOF. The Foundation is registered with the objective of Global Outreach with dedicated programmes that Enkindle and Empower the society. DGOF has various initiatives to aid the upliftment of students as well as the general public at all levels and age groups.
                        </p>
                        <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                            Her passion to cater to children in their formative years has paved the way for APPS - the first initiative under the DGO Foundation. Here, toddlers receive all-round development and are prepared for their formal education with a strong foundation and different outlook. Students will Relish Learning at APPS.
                        </p>
                        <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                            Through various parent-oriented programmes, the preschool will also be a platform for young parents to grow with their child and raise their child right, thus, ensuring all-round child development.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamMemberSection;
