import React from 'react'

import { Helmet } from 'react-helmet'

import Hero from '../components/hero'
import Features1 from '../components/features1'
import Features2 from '../components/features2'
import Contact from '../components/contact'
import './home.css'
import MissionSection from '../components/mission'
import InitiativesSection from '../components/initiatives'
import ProfileSection from '../components/profile'
import logo from '../img/logos/logo.png'

const Menu = () => {
  return <>
    <div data-thq="thq-navbar-nav" className="home-desktop-menu">
      <nav className="home-links1">
        <span className="thq-link thq-body-small">Home</span>
        <span className="thq-link thq-body-small">About Us</span>
        <span className="thq-link thq-body-small">
          Education Programs
        </span>
        <span className="thq-link thq-body-small">Women Empowerment</span>
        <span className="thq-link thq-body-small">
          Elder Care Programs
        </span>
      </nav>
      <div className="home-buttons1"></div>
    </div>
    <div data-thq="thq-burger-menu" className="home-burger-menu">
      <svg viewBox="0 0 1024 1024" className="home-icon1">
        <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
      </svg>
    </div>
    <div data-thq="thq-mobile-menu" className="home-mobile-menu">
      <div className="home-nav">
        <div className="home-top">
          <img
            alt="DGO Foundation"
            src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&amp;force_format=original"
            className="home-logo" />
          <div data-thq="thq-close-menu" className="home-close-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon3">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        <nav className="home-links2">
          <span className="thq-link thq-body-small">Home</span>
          <span className="thq-link thq-body-small">About Us</span>
          <span className="thq-link thq-body-small">
            Education Programs
          </span>
          <span className="thq-link thq-body-small">
            Women Empowerment
          </span>
          <span className="thq-link thq-body-small">
            Elder Care Programs
          </span>
        </nav>
      </div>
      <div className="home-buttons2">
        <button className="thq-button-filled">Login</button>
        <button className="thq-button-outline">Register</button>
      </div>
    </div>
  </>
}
const Home = (props) => {
  return (
    <div className="">
      <Helmet>
        <title>Deep Global Outreach Foundation (DGOF) Trust</title>
      </Helmet>

      <Hero></Hero>
      <Features1></Features1>
      <Features2></Features2>
      <MissionSection></MissionSection>
      <InitiativesSection></InitiativesSection>
      <ProfileSection></ProfileSection>
      <Contact></Contact>
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Home
