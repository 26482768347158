import React from 'react';
import logo from '../img/logos/logo.png'
import appsLogo from '../img/logos/apps.png'
import getLogo from '../img/logos/get.png'
import btLogo from '../img/logos/bt.png'
import nguLogo from '../img/logos/ngu.png'

// Define an array of fundraising events
const initiatives = [
    {
        id: 1,
        imageSrc: appsLogo,
        title: 'Apple Pie Prep School',
        description: 'Apple Pie Prep School (APPS) caters to children in their early years, ensuring their holistic development through school readiness programmes and helping them grow with a more unique outlook.APPS not only provides opportunities for children to grow, but also offers a chance for young parents to grow with their little ones through various parent-oriented development workshops and programmes.',
    },
    {
        id: 2,
        imageSrc: nguLogo,
        title: 'The ‘Never Give Up’ Academy',
        description: 'The ‘Never Give Up’ Academy(NGUA) began with the vision to find deserving students with potential and provide them coaching support so that they can realise and redefine their aptitude to follow their dreams to become skilled professionals.The coaching would range from courses like IIT - JEE, civil services, vocational training and programmes for reskilling and upskilling.',
    },
    {
        id: 2,
        imageSrc: getLogo,
        title: 'Good Earth Tech',
        description: 'Good Earth Tech (GET) was conceived with the objective to bring out innovative ideas to make our planet more liveable for the future generations through awareness programmes on healthy, hygienic living and waste management initiatives.GET aims to educate the general public on how each household’s contribution can bring about great change through effective organic farming for the sustainability and preservation of our environment.',
    },
    {
        id: 2,
        imageSrc: btLogo,
        title: 'Bliss Town',
        description: 'Bliss Town(BT) is the outcome of our firm belief in the fact that humans evolve till the end of their life.For us, elder citizens are not the exhausted section of the society. BT—an elite retirement township, is designed exclusively to cater to the emotional and psychological needs of our elders, encouraging them to contribute to the society with self - realisation.We hope to help them lead a confident and content life.',
    }
]

const InitiativeCard = ({ imageSrc, amountRaised, goal, donors, progress, title, description }) => {
    return (
        <div className="p-5 space-y-4 bg-white border border-gray-200 rounded-lg shadow-md lg:p-8">
            <img className="object-cover w-full rounded-lg shadow-lg" src={imageSrc} alt="" />

            <h3 className="text-lg font-bold leading-tight text-gray-900 ">{title}</h3>
            <p className="text-base font-normal text-gray-500 ">{description}</p>
        </div>
    );
};

const InitiativesSection = () => {
    return (
        <section className="bg-white">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-4xl">
                        Our<span className='text-lime-500'> Initiatives</span>
                    </h2>
                    <p className="mt-4 text-base font-normal text-gray-500 ">
                    </p>
                </div>

                <div className="grid grid-cols-1 gap-4 mt-8 xl:gap-12 md:grid-cols-2">
                    {initiatives.map(event => (
                        <InitiativeCard
                            key={event.id}
                            imageSrc={event.imageSrc}
                            amountRaised={event.amountRaised}
                            goal={event.goal}
                            donors={event.donors}
                            progress={event.progress}
                            title={event.title}
                            description={event.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default InitiativesSection;
