import React from 'react';

const MissionSection = () => {

    const articles = [
        {
            title: 'For Students',
            text: <ul>
                <li>- To help them in their formative years to develop national integrity and mould them to become nation-builders</li>
                <li>- To provide an opportunity for young parents to understand their children better and grow with them</li>
                <li>- To aid aspiring and deserving students to redefine their ambition and become successful professionals</li>
            </ul>,
        },
        {
            title: 'For Adults',
            text: <ul>

                <li>- To become socially more liveable by providing workshops on self-improvement and positive self-image</li>
                <li>- To provide a holistic community for senior citizens to make them realise their usefulness and potential in their second innings</li>
            </ul>,
        },
        {
            title: 'For Everyone',
            text: '- To increase planet preservation awareness through various innovative and technological initiatives',
        },
    ]
    return (
        <section className="bg-white dark:bg-gray-900 antialiased">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-20">
                    <article>
                        <a href="#" title="">
                            <img
                                className="object-cover w-full rounded-lg"
                                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/ngo-carousel/image-4.jpg"
                                alt="featured"
                            />
                        </a>

                        <div className="mt-5 space-y-3">


                            <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                                <span className='font-thin italic text-white'>“Change will not come if we wait for some other person or some other time. We are the ones we’ve been waiting for. We are the change that we seek.”</span>
                            </p>

                            <div className="flex items-center gap-3">
                                <img
                                    className="w-8 h-8 rounded-full"
                                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gough.png"
                                    alt="author"
                                />
                                <div className="text-md font-medium leading-tight text-gray-900 dark:text-white">
                                    <div>Barack Obama</div>
                                </div>
                            </div>

                        </div>
                    </article>

                    <div className="space-y-8">
                        <h2 className="text-4xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                            <a href="#" className="hover:underline" title="">
                                THE <span className='text-lime-400'>DGOF</span> Mission
                            </a>
                        </h2>
                        {articles.map(article => <Article article={article} />)}

                    </div>
                </div>
            </div>
        </section>
    );

    function Article({ article }) {
        return <article>
            <div className="space-y-3">


                <h2 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
                    <a href="#" className="hover:underline" title="">
                        {article.title}
                    </a>
                </h2>

                <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                    {article.text}
                </p>
            </div>
        </article>;
    }
};

export default MissionSection;
